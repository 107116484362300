<template>
  <div>
    <div id="surveyElement">
      <Bookings :Header="title" :id="id" :Postid="postid" />
    </div>
    <v-row class="Cancel">
      <v-spacer></v-spacer>
      <v-btn rounded block x-large color="#EDBC7B" to="/">Cancel</v-btn>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
import Bookings from "../components/MakeBooking";

export default {
  name: "Display",
  components: {
    Bookings,
  },
  data() {
    return {
      title: null,
      id: null,
      postid: null
    };
  },
  created() {
    let form = JSON.parse(localStorage.getItem("Display_Form"))
    this.title = form.Title
    this.id = form.ID
    this.postid = form.PostID
  },
  methods: {},
  computed: {},
};

// props: {
//     title: {
//       Type: String,
//       Required: true
//     },
//     id: {
//       Type: String,
//       Required: true,
//     },
//     postid: {
//       Type: String,
//       Required: true,
//     },
//   },
</script>

<style lang="scss" scoped>

.Cancel {
    margin: 25px;
}
</style>

