<template>
  <v-container>
    <v-row class="Header">
       <v-img :src="require('../assets/BQ_Awards.png')" contain height="150" />
        <v-spacer></v-spacer>
      <v-col cols="12">
        <h1>{{ Header }}</h1>
      </v-col>
    </v-row>
    <v-card class="mb-0" elevation="24">
      <survey :survey="survey" />
    </v-card>
  </v-container>
</template>

<script>
import * as Survey from "survey-vue";
import "survey-vue/survey.css";
import "./index.css";
import router from "../router/index";
import validVouchers from "../Data/validVouchers.json";

//Survey.StylesManager.applyTheme("orange");
var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "#EDBC7B";
//defaultThemeColors["$main-color"] = "orange";
defaultThemeColors["$main-hover-color"] = "#a65818";
defaultThemeColors["$text-color"] = "#4a4a4a";
defaultThemeColors["$header-color"] = "#EDBC7B";
//defaultThemeColors["$header-color"] = "orange";
//defaultThemeColors["$body-background-color"] = "green";

defaultThemeColors["$header-background-color"] = "#4a4a4a";
defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
/*
default: {
      "$header-background-color": "#e7e7e7",
      "$body-container-background-color": "#f4f4f4",

      "$main-color": "#1ab394",
      "$main-hover-color": "#0aa384",
      "$body-background-color": "white",
      "$inputs-background-color": "white",
      "$text-color": "#6d7072",
      "$text-input-color": "#6d7072",
      "$header-color": "#6d7072",
      "$border-color": "#e7e7e7",

      "$error-color": "#ed5565",
      "$error-background-color": "#fd6575",

      "$progress-text-color": "#9d9d9d",
      "$disable-color": "#dbdbdb",
      "$disabled-label-color": "rgba(64, 64, 64, 0.5)",
      "$slider-color": "white",
      "$disabled-switch-color": "#9f9f9f",
      "$disabled-slider-color": "#cfcfcf",
      "$foreground-light": "#909090",
      "$foreground-disabled": "#161616",
      "$background-dim": "#f3f3f3",

      "$progress-buttons-color": "#8dd9ca",
      "$progress-buttons-line-color": "#d4d4d4"
    },
*/
Survey.StylesManager.applyTheme();

function checkVoucher(params) {
  let vouchers = validVouchers;
  let result = "Not Valid";
  if (!params && params.length < 1) return result;

  params[0] = params[0].toUpperCase();
  params[1] = params[1].toUpperCase();
  params[2] = params[2].toUpperCase();

  console.log(params[0]);
  console.log(params[1]);
  console.log(params[2]);

  vouchers.forEach((element) => {
    element.Firstname = element.Firstname.toUpperCase();
    console.log(
      params[0].substr(0, 1) == element.Firstname.substr(0, 1) &&
        params[1] == element.Lastname.toUpperCase() &&
        params[2] == element.Email.toUpperCase()
    );
    if (
      params[0].substr(0, 1) == element.Firstname.substr(0, 1) &&
      params[1] == element.Lastname.toUpperCase() &&
      params[2] == element.Email.toUpperCase()
    ) {
      result = element.Code;
    }
    // console.log(element.Firstname.substr(0, 1));
    // console.log(element.Lastname.toUpperCase());
    // console.log(element.Email.toUpperCase());
  });

  return result;
}

// <div>
//     <iframe src="https://siteextensions.ownurgoal.net.au/Calendar" width="80%" height="800" style="border:1px solid black;">
// 	</iframe>
// </div>

export default {
  props: {
    Header: {
      Type: String,
      Default: "This is the default title", // seconds
    },
    id: {
      Type: String,
      Required: true,
    },
    Postid: {
      Type: String,
      Required: true,
    },
  },
  name: "Bookings-component",
  data() {
    const description = this.Header;
    const surveyid = this.id;
    const json = { surveyId: this.id };
    const survey = new Survey.Model(json);

    Survey.surveyStrings.loadingSurvey =
      "Please wait. we are loading the form…";

    // use these to load data back to SurveyJs if required
    survey.surveyPostId = this.Postid;
    //Optionally, show saving progress and show an error and "Save Again" button if the results can't be stored.
    survey.surveyShowDataSaving = true;
    Survey.surveyStrings.savingData =
      "Please wait. We are validating and saving your response.";
    Survey.surveyStrings.savingDataError = "We had trouble saving your entries";
    // Survey.surveyStrings.savingDataSuccess =
    //   "We saved your information sucessfully - Thank you !";
    Survey.surveyStrings.saveAgainButton = "Try to save again.";

    // Display a preview before proceeding to a payment screen
    // survey.showPreviewBeforeComplete = "showAnsweredQuestions";

    Survey.FunctionFactory.Instance.register("checkVoucher", checkVoucher);

    survey.onComplete.add(function(survey) {
      let ticket = survey.data
      let amount = 0
      if (typeof ticket.Total == 'number') {
        amount = Number(ticket.Total)
      } else {
        amount = parseFloat(ticket.Total.replace(/\$|,/g, ''));
      }
      ticket.Total = amount;
      ticket.Description = description
      ticket.id = surveyid
      localStorage.setItem("Tickets", JSON.stringify(ticket));
      // if amount greater than zero then send to PIN payment view
      if ( amount > 0) {
        router.push("/PinPay");
      }
    });
    return {
      survey: survey,
    };
  },
  methods: {},
};
</script>

<style scoped>
.Header {
  text-align: center;
  margin-bottom: 5px;
  margin-top: 15px;
  color: #000000;
}

.pay-button {
  text-align: center;
  background-color: green;
  color: white;
  font-size: 15px;
  margin-top: 25px;
}

h1, h2 {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 15px;
    color: #830027;
}

</style>
